import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RepeatMode} from '@/services/trackPlayer';
import {RateMode} from '@/services/trackPlayer/types';
import {IQueue, IQueueItem} from '@/types/common';
import {mapTrackIdToQueue} from '@/utils/player';

export interface PlayerState {
  queue: IQueue | null;
  isPlaying: boolean;
  isBuffering: boolean;
  repeatMode: RepeatMode;
  rateMode: RateMode;
  volume: number;
  dbSyncTimestamp: number;
  isActive: boolean;
}

export const initialState: PlayerState = {
  queue: null,
  isPlaying: false,
  isBuffering: false,
  repeatMode: RepeatMode.Off,
  rateMode: 1,
  volume: 1,
  dbSyncTimestamp: Date.now(),
  isActive: true,
};

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    updateSyncTime: state => {
      state.dbSyncTimestamp = Date.now();
    },
    setQueue: (state, action: PayloadAction<IQueue | null>) => {
      state.queue = action.payload;
    },
    reorderQueueTracks: (state, action: PayloadAction<IQueueItem[]>) => {
      if (state.queue) {
        state.queue.items = action.payload;
      }
    },
    setCurrentTrackKey: (state, action: PayloadAction<string>) => {
      if (state.queue) {
        state.queue.currentTrackKey = action.payload;
      }
    },
    addTrackToQueue: (state, action: PayloadAction<{trackId: string}>) => {
      if (state.queue) {
        const item = mapTrackIdToQueue(action.payload.trackId);
        state.queue.items = [...state.queue.items, item];

        if (state.queue.shuffledItems.length > 0) {
          state.queue.shuffledItems = [...state.queue.shuffledItems, item];
        }
      }
    },
    removeTrackFromQueue: (
      state,
      action: PayloadAction<{trackKey: string}>,
    ) => {
      if (state.queue) {
        state.queue.items = state.queue.items.filter(
          item => item.key !== action.payload.trackKey,
        );

        if (state.queue.shuffledItems.length > 0) {
          state.queue.shuffledItems = state.queue.shuffledItems.filter(
            item => item.key !== action.payload.trackKey,
          );
        }
      }
    },
    removeTrackFromQueueByTrackId: (
      state,
      action: PayloadAction<{trackId: string}>,
    ) => {
      if (state.queue) {
        state.queue.items = state.queue.items.filter(
          item => item.trackId !== action.payload.trackId,
        );

        if (state.queue.shuffledItems.length > 0) {
          state.queue.shuffledItems = state.queue.shuffledItems.filter(
            item => item.trackId !== action.payload.trackId,
          );
        }
      }
    },
    setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload;
    },
    setIsBuffering: (state, action: PayloadAction<boolean>) => {
      state.isBuffering = action.payload;
    },
    setRepeatMode: (state, action: PayloadAction<RepeatMode>) => {
      state.repeatMode = action.payload;
    },
    setRateMode: (state, action: PayloadAction<RateMode>) => {
      state.rateMode = action.payload;
    },
    setVolume: (state, action: PayloadAction<number>) => {
      state.volume = action.payload;
    },
    setShuffleMode: (
      state,
      action: PayloadAction<{shuffledItems: IQueueItem[]}>,
    ) => {
      if (state.queue) {
        state.queue.shuffledItems = action.payload.shuffledItems;
      }
    },
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
  },
});

export const {
  updateSyncTime,
  setQueue,
  reorderQueueTracks,
  addTrackToQueue,
  removeTrackFromQueue,
  removeTrackFromQueueByTrackId,
  setCurrentTrackKey,
  setIsPlaying,
  setIsBuffering,
  setRepeatMode,
  setRateMode,
  setVolume,
  setShuffleMode,
  setIsActive,
} = playerSlice.actions;

export default playerSlice.reducer;
