import {ShareOptions} from 'react-native-share';

import CustomIcons from '@/assets/icons';
import {IDynamicTheme} from '@/types/theme';
import {IBaseUser} from '@/types/user';

type Rect = {
  bottom?: number;
  left?: number;
  right?: number;
  top?: number;
};

export type RectOrSize = Rect | number;

export type IconsKeys = keyof typeof CustomIcons;

export interface INormalized<Type> {
  ids: string[];
  items: {
    [key: string]: Type;
  };
}

export interface IMusicPlatformData {
  id: string;
  name: string;
}

export type MusicPlatform = string;

export interface IBaseArtist extends IDynamicTheme {
  id: string;
  name: string;
  slug: string;
  description?: string;
  avatarUrl?: string;
  userId: string;
}

export interface IArtist extends IBaseArtist {
  createdAtTime: string;
  externalLinks: IExternalLink[];
  addresses: IAddress[];
}

export interface IArtistWithTracks extends IArtist {
  tracks: ITrack[];
}

type EditableArtistKeys =
  | 'name'
  | 'avatarUrl'
  | 'description'
  | 'predefinedThemeName'
  | 'customTheme';

export type IArtistUpdate = Pick<IArtist, 'id'> &
  Partial<Pick<IArtist, EditableArtistKeys>> &
  EditableAvatar;

export interface ITrack {
  id: string;
  platformInternalId: string;
  title: string;
  slug: string;
  platformId: MusicPlatform;
  artistId: string;
  artist: IArtist;
  supportingArtist: IBaseArtist | null;
  lossyAudioUrl: string;
  lossyArtworkUrl?: string;
  lossyArtworkIpfsHash?: string;
  lossyArtworkMimeType?: string;
  description?: string;
  createdAtTime?: string;
  websiteUrl?: string | null; // could be empty string
  duration?: number;
  chorusStart?: number;
  mintStart?: string;
}

export interface ITrackDetails {
  id: string;
  description: string;
}

export interface INFTFactory {
  address: string;
  standard: 'erc721' | 'erc1155';
}

export interface ITrackWithNFTFactory extends ITrack {
  nftFactory?: INFTFactory;
}

export interface ICollectionTrack extends ITrack {
  count: number;
}

export interface IDatabaseTracks {
  [id: string]: ITrack;
}

export interface IDatabaseArtists {
  [id: string]: IArtist;
}

export interface IDatabase {
  tracks: IDatabaseTracks;
  artists: IDatabaseArtists;
}

export enum PlayContextType {
  favorites = 'favorites',
  artist = 'artist',
  playlist = 'playlist',
  ownCollection = 'ownCollection',
  playlistSuggestions = 'playlistSuggestions',
  collection = 'collection',
  walletCollection = 'walletCollection',
  platform = 'platform',
  search = 'search',
  trackDetails = 'trackDetails',
  latest = 'latest',
  trending = 'trending',
  history = 'history',
  releasesForYou = 'releasesForYou',
  feed = 'feed',
  feedLikes = 'feedLikes',
  feedHides = 'feedHides',
  feedArtist = 'feedArtist',
  external = 'external',
}

export type PlayContextSource =
  | 'LatestExplore'
  | 'LatestMore'
  | 'TrendingExplore'
  | 'TrendingMore'
  | 'Search'
  | 'TrackDetails'
  | 'Playlist'
  | 'PlaylistSuggestions'
  | 'OwnPlaylist'
  | 'Platform'
  | 'Artist'
  | 'Collection'
  | 'OwnCollection'
  | 'WalletCollection'
  | 'Favorites'
  | 'History'
  | 'ReleasesForYou'
  | 'ReleasesForYouExplore'
  | 'Feed'
  | 'External'
  | 'FeedExplore';

export interface IQueueContext {
  type: PlayContextType;
  source: PlayContextSource;
  id?: string;
  slug?: string;
  title?: string;
  titleId?: string;
}

export interface IQueueItem {
  trackId: string;
  key: string;
  originalIndex?: number;
}

export interface IQueueItemWithTrack extends IQueueItem {
  track: ITrack;
}

export interface IQueue {
  currentTrackKey: string;
  items: IQueueItem[];
  shuffledItems: IQueueItem[];
  context: IQueueContext;
}

export interface ISyncedRecord {
  updatedAtTime?: number; //unix milis time for last cleint update
  serverUpdatedAtTime?: number; //unix milis time for last client update the server knows about

  abandoned?: boolean;
}

export interface IShareProperties extends ShareOptions {
  title?: string;
  titleId?: string;
  message?: string;
  messageId?: string;
  url: string;
}

export interface INFT {
  id: string;
}

export type NFTCollectionMap = {
  [key: MusicPlatform]: INFT[];
};

export interface IResponsiveInfo {
  isMobile: boolean;
  isSmallDesktop: boolean;
  isDesktop: boolean;

  isBrowser: boolean;
  isMobileBrowser: boolean;
}

export interface ICurrency {
  symbol: string;
  decimals: number;
}

export type AddressString = `0x${string}`;

export interface IAddressMetadata {
  spinampWallet?: {
    version: {
      lib: string;
      libVersion: string;
      accountType: string;
      factoryAddress: AddressString;
      accountLogicAddress: AddressString;
    };
    signer: string;
    credentialId: string;
    deployed: string[];
  };
}
export interface IAddressProperties {
  isPublic: boolean;
  isSession: boolean;
  isPasskey: boolean;
  isWallet: boolean;
  metadata?: IAddressMetadata;
}

export interface IAddress extends IAddressProperties {
  address: AddressString;
  ens: string | null;
  avatarUrl?: string | null;
}

export interface IAddressWithOwner extends IAddress {
  user?: IBaseUser;
}

export type SignMessage = (msg: string) => Promise<string>;

export interface IBalance {
  decimals: number;
  formatted: string;
  symbol: string;
  value: bigint;
}

export interface IExchangeRates {
  [chainId: number]: number;
}

export type EditableAvatar = {
  avatarUrl?: string;
  avatarIPFSHash?: string;
};

export interface IExternalLink {
  id: string;
  type: string;
  url: string;
  updatedAtTime: Date;
}
