import {StackActions, useNavigation} from '@react-navigation/native';
import React, {FC, useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {View} from 'react-native';
import Animated, {FadeIn} from 'react-native-reanimated';

import {FadeInOut} from '@/components/AnimationWrappers';
import Card from '@/components/Card/Card';
import CollapsingText from '@/components/CollapsingText';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import ImageWithPreview from '@/components/ImageWithPreview';
import Link from '@/components/Link';
import Loader from '@/components/Loader/Loader';
import ProviderLink from '@/components/ProviderLink/ProviderLink';
import Share from '@/components/Share/Share';
import Space from '@/components/Space/Space';
import {AutoLinkText} from '@/components/Text/AutoLinkText';
import Text from '@/components/Text/Text';
import TrackPlaylistToggler from '@/components/TrackPlaylistToggler/TrackPlaylistToggler';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useFavToggle} from '@/hooks/useFavToggle';
import {useAppDispatch} from '@/hooks/useRedux';
import {useTrackReleaseNotification} from '@/hooks/useTrackReleaseNotification';
import {useCollect} from '@/modules/Collect';
import {useExternalSigner} from '@/modules/ExternalWallet';
import {playNewQueue} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {ITrack, PlayContextType} from '@/types/common';
import {ImageSize} from '@/types/media';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {getFormattedTimeToRelease} from '@/utils/dateTime';
import {openLinkSafe} from '@/utils/linking';
import {isDesktopBrowser, isWeb} from '@/utils/platform';
import {getTrackUrl} from '@/utils/share';
import {stripHtmlString} from '@/utils/string';
import {getTrackArtists} from '@/utils/tracks';

import {styles} from './Track.style';

interface IProps {
  track: ITrack;
  showLoader: boolean;
  linkEnabled: boolean;
  description?: string;
  referral?: string;
  autoPlay?: boolean;
  redirect?: string;
}

const TrackInfo: FC<IProps> = ({
  track,
  showLoader,
  description,
  referral,
  autoPlay = false,
  redirect,
  linkEnabled,
}) => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const {formatDate} = useIntl();
  const {address} = useExternalSigner();

  const {
    collect,
    icon: collectIcon,
    isLoading: isCollectLoading,
    textId: collectTextId,
  } = useCollect(track, referral);

  const {isFav, toggleFav} = useFavToggle(track.id);

  const isReleased =
    !track.mintStart || new Date(track.mintStart) <= new Date();

  const releaseNotification = useTrackReleaseNotification({
    track,
    analyticsInfo: {screen: Routes.Track, id: track.id},
  });

  const formattedDescription = useMemo(
    () => (description ? stripHtmlString(description) : ''),
    [description],
  );

  const getArtistLink = (slugOrId: string) => ({
    to: {
      screen: Routes.MainNavigation,
      params: {
        screen: Routes.Artist,
        params: {slugOrId},
      },
    },
    action: StackActions.push(Routes.Artist, {slugOrId}),
  });

  const trackLink = {
    to: {
      screen: Routes.MainNavigation,
      params: {
        screen: Routes.Track,
        params: {slug: track.slug, referral: referral},
      },
    },
  };

  const onPlay = () => {
    if (track) {
      dispatch(
        playNewQueue({
          trackId: track.id,
          trackIds: [track.id],
          context: {
            source: 'TrackDetails',
            type: PlayContextType.trackDetails,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (autoPlay) {
      onPlay();

      if (redirect && !isWeb) {
        openLinkSafe(redirect);
        return;
      }

      if (!isDesktopBrowser) {
        navigation.navigate(Routes.Player);
      }
    }
  }, [autoPlay, redirect]);

  const trackTitle = (
    <Text size="l" weight="semibold">
      {track.title}
    </Text>
  );

  return (
    <>
      <Space style={style.header}>
        <Card style={style.coverArtCard}>
          {track.lossyArtworkUrl && (
            <ImageWithPreview
              resizeWidth={ImageSize.track}
              source={{uri: track.lossyArtworkUrl}}
              style={style.coverArt}
              hasBorderRadius
            />
          )}
        </Card>
        <Space flex style={style.headerInfo}>
          {linkEnabled ? (
            <Link {...trackLink}>{trackTitle}</Link>
          ) : (
            <>{trackTitle}</>
          )}
          <Space mt="xxs" />

          <Text numberOfLines={2}>
            {getTrackArtists(track).map((artist, index) => (
              <React.Fragment key={artist.id}>
                {index > 0 && (
                  <View>
                    <Text>{' & '}</Text>
                  </View>
                )}
                <Link {...getArtistLink(artist.slug)}>
                  {({hover}) => <Text underline={hover}>{artist.name}</Text>}
                </Link>
              </React.Fragment>
            ))}
          </Text>

          <Space pt="xs" flex />

          {track.platformId === 'spinamp' ? (
            <Text size="xs">Spinamp Originals</Text>
          ) : (
            <ProviderLink
              platformId={track.platformId}
              url={track.websiteUrl}
              analyticsInfo={{
                screen: Routes.Track,
                id: track.id,
              }}
              size="xs"
              referral={referral}
            />
          )}
        </Space>
      </Space>

      <Space mt="m" style={style.actions}>
        {isReleased ? (
          <FadeInOut enabled={isCollectLoading}>
            <TransparentButton
              onPress={collect}
              rippleDisabled={false}
              rippleScale={1}
              iconSpacing={spacing.xxs}
              style={style.collectButton}
              text={{id: collectTextId}}
              icon={{
                provider: 'custom',
                name: collectIcon,
              }}
            />
          </FadeInOut>
        ) : (
          <TransparentButton
            rippleDisabled={false}
            rippleScale={1}
            iconSpacing={spacing.xxs}
            style={style.collectButton}
            disabled={releaseNotification.isSubscribed}
            onPress={releaseNotification.subscribe}
            text={{id: releaseNotification.textId}}
            icon={{
              provider: 'custom',
              name: releaseNotification.iconName,
              fill: releaseNotification.isSubscribed,
            }}
          />
        )}

        <IconButton
          onPress={toggleFav}
          icon={{
            name: 'heart',
            provider: 'custom',
            fill: isFav,
            color: isFav ? 'favoritesColor' : 'textColor',
          }}
        />
        <Share url={getTrackUrl(track, address)} title={track.title}>
          {({onPress, icon: shareIcon}) => (
            <IconButton onPress={onPress} icon={shareIcon} />
          )}
        </Share>
        <TrackPlaylistToggler track={track}>
          {({openModal}) => (
            <IconButton
              onPress={openModal}
              icon={{
                name: 'playlistAdd',
                provider: 'custom',
              }}
            />
          )}
        </TrackPlaylistToggler>
        <IconButton
          onPress={onPlay}
          icon={{
            name: 'play',
            provider: 'custom',
          }}
        />
      </Space>
      {showLoader ? (
        <Space pv="xxl">
          <Loader size="s" />
        </Space>
      ) : (
        <Animated.View entering={FadeIn} style={style.detailsContainer}>
          {!!formattedDescription && (
            <View>
              <Text weight="semibold" id="track.about" size="m" />
              <Space mt="xs" />
              <CollapsingText>
                <AutoLinkText size="xs">{formattedDescription}</AutoLinkText>
              </CollapsingText>
            </View>
          )}

          {track.mintStart && (
            <View style={style.releaseInfo}>
              <Text
                weight="semibold"
                size="xs"
                id={isReleased ? 'track.released' : 'track.timeToRelease'}
              />
              <Text size="xs">
                {isReleased
                  ? formatDate(track.mintStart, {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    })
                  : getFormattedTimeToRelease(track.mintStart)}
              </Text>
            </View>
          )}

          <View style={style.headerRow}>
            <Icon name="trending-up" provider="material" size={18} />
            <Space ml="xs" />
            <Text weight="semibold" id="track.activity" size="m" />
          </View>
        </Animated.View>
      )}
    </>
  );
};

export default React.memo(TrackInfo);
