import {batch} from 'react-redux';

import {RepeatMode, TrackPlayer} from '@/services/trackPlayer';
import {AppDispatch, RootState} from '@/store';
import {savePlaybackEvents, trackEvent} from '@/store/events';
import {selectCurrentScreen} from '@/store/navigation';
import {
  selectCurrentTrack,
  selectCurrentTrackId,
  selectIsShuffle,
  selectQueue,
  selectRateMode,
  selectVolume,
  setIsBuffering,
  setQueue,
  setRateMode,
  setShuffleMode,
  setVolume,
} from '@/store/player';
import {
  selectHasNext,
  selectIsPlaying,
  selectNextTrack,
  selectPreviousTrack,
  selectRepeatMode,
} from '@/store/player/selectors';
import {
  setCurrentTrackKey,
  setIsActive,
  setIsPlaying,
  setRepeatMode,
} from '@/store/player/slice';
import {IQueue, IQueueContext} from '@/types/common';
import {TrackPlayedTrigger} from '@/types/events';
import {getTrackById} from '@/utils/db';
import {mapTrackIdToQueue, shuffleQueue} from '@/utils/player';

export const restorePlayerState =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setIsPlaying(false));
    dispatch(setIsBuffering(false));
    dispatch(setIsActive(true));

    TrackPlayer.setVolume(selectVolume(getState()));

    const currentTrack = selectCurrentTrack(getState());

    if (currentTrack) {
      await TrackPlayer.reset();
      TrackPlayer.add(currentTrack);
    }
  };

export const handlePlaybackError = () => async (dispatch: AppDispatch) => {
  dispatch(setIsPlaying(false));
  dispatch(setIsBuffering(false));
  await TrackPlayer.reset();
};

const handleSkipEvents =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const currentQueue = selectQueue(state);
    const currentTrackId = selectCurrentTrackId(state);
    const timestamp = Date.now();

    if (currentQueue && currentTrackId) {
      const [position, duration] = await Promise.all([
        TrackPlayer.getPosition(),
        TrackPlayer.getDuration(),
      ]);
      const skipEvent = {
        timestamp,
        trackId: currentTrackId!,
        context: {
          source: currentQueue.context.source,
          type: currentQueue.context.type,
          id: currentQueue.context.id,
        },
        additionalData: {
          skippedPosition: position,
        },
      };

      if (position < 5) {
        dispatch(
          trackEvent({
            eventName: 'TrackFastSkipped',
            ...skipEvent,
          }),
        );
      } else if (duration && position < duration) {
        dispatch(
          trackEvent({
            eventName: 'TrackSkipped',
            ...skipEvent,
          }),
        );
        dispatch(savePlaybackEvents());
      }
    }
  };

const playTrack =
  (
    action: {
      trackKey: string;
      queue?: Omit<IQueue, 'currentTrackKey' | 'shuffledItems'>;
    },
    playTrigger: TrackPlayedTrigger = 'Direct',
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const isShuffle = selectIsShuffle(state);
    const repeatMode = selectRepeatMode(state);
    const currentScreen = selectCurrentScreen(state);
    const currentQueue = selectQueue(state);
    const newQueue = action.queue;
    const queue = newQueue || currentQueue;
    const newTrackId = queue?.items.find(
      item => item.key === action.trackKey,
    )?.trackId;
    const newTrack = newTrackId && getTrackById(newTrackId);

    dispatch(handleSkipEvents());

    if (newTrack) {
      batch(() => {
        if (newQueue) {
          const shouldDisableShuffle =
            !isShuffle ||
            (newQueue &&
              currentQueue &&
              (newQueue.context.type !== currentQueue.context.type ||
                newQueue.context.id !== currentQueue.context.id));

          dispatch(
            setQueue({
              ...newQueue,
              currentTrackKey: action.trackKey,
              shuffledItems: shouldDisableShuffle
                ? []
                : shuffleQueue(newQueue.items),
            }),
          );
        } else {
          dispatch(setCurrentTrackKey(action.trackKey));
        }
        dispatch(setIsPlaying(true));
        dispatch(setRateMode(1));

        if (queue) {
          dispatch(
            trackEvent({
              eventName: 'TrackPlayed',
              trackId: newTrackId,
              context: {
                source: queue.context.source,
                type: queue.context.type,
                id: queue.context.id,
              },
              additionalData: {
                trigger: playTrigger,
                screen: playTrigger === 'Direct' ? currentScreen : undefined,
                isShuffle: isShuffle,
                isRepeatQueue: repeatMode === RepeatMode.Queue,
                isRepeatSingle: repeatMode === RepeatMode.Track,
              },
            }),
          );
        }
      });

      await TrackPlayer.playTrack(newTrack);
    }
  };

export const playNewQueue =
  (action: {
    trackIds: string[];
    context: IQueueContext;
    trackId?: string;
    index?: number;
  }) =>
  (dispatch: AppDispatch) => {
    const items = action.trackIds.map(mapTrackIdToQueue);
    const trackKey =
      action.trackId &&
      items.find(item => item.trackId === action.trackId)?.key;

    dispatch(
      playTrack({
        // 1. If index is passed, play track from specific index
        // 2. If trackId is passed, find first queue item with matching trackId
        // 3. Play first item otherwise
        trackKey: items[action.index || -1]?.key || trackKey || items[0].key,
        queue: {
          items,
          context: action.context,
        },
      }),
    );
  };

export const playTrackInQueue =
  (action: {trackKey: string}, playTrigger?: TrackPlayedTrigger) =>
  (dispatch: AppDispatch) =>
    dispatch(
      playTrack(
        {
          trackKey: action.trackKey,
        },
        playTrigger,
      ),
    );

export const pause = () => (dispatch: AppDispatch) => {
  dispatch(setIsPlaying(false));
  TrackPlayer.pause();
};

export const resume = () => (dispatch: AppDispatch) => {
  dispatch(setIsPlaying(true));
  TrackPlayer.play();
};

export const togglePlay =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const isPlaying = selectIsPlaying(getState());

    if (isPlaying) {
      dispatch(pause());
    } else {
      dispatch(resume());
    }
  };

export const seekTo = (position: number) => () => TrackPlayer.seekTo(position);

export const playNextTrack =
  (isAutoPlayed = false) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const nextTrack = selectNextTrack(state);
    const repeatMode = selectRepeatMode(state);
    const playTrigger = isAutoPlayed ? 'Auto' : 'Next';

    if (nextTrack) {
      await dispatch(playTrackInQueue({trackKey: nextTrack.key}, playTrigger));
    }

    if (repeatMode === RepeatMode.Track) {
      dispatch(setRepeatMode(RepeatMode.Queue));
    }
  };

export const playPreviousTrack =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const repeatMode = selectRepeatMode(getState());
    const previousTrack = selectPreviousTrack(state);
    const progressPosition = await TrackPlayer.getPosition();

    if (progressPosition < 3 && previousTrack) {
      await dispatch(
        playTrackInQueue(
          {
            trackKey: previousTrack.key,
          },
          'Previous',
        ),
      );

      if (repeatMode === RepeatMode.Track) {
        dispatch(setRepeatMode(RepeatMode.Queue));
      }
    } else {
      await TrackPlayer.seekTo(0);
      dispatch(resume());
    }
  };

export const onTrackEnded =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const repeatMode = selectRepeatMode(state);
    const isShuffle = selectIsShuffle(state);
    const hasNext = selectHasNext(state);
    const queue = selectQueue(state);
    const currentTrackId = selectCurrentTrackId(state);

    if (queue) {
      dispatch(
        trackEvent({
          eventName: 'TrackEnded',
          trackId: selectCurrentTrackId(state)!,
          context: {
            source: queue.context.source,
            type: queue.context.type,
            id: queue.context.id,
          },
        }),
      );
      dispatch(savePlaybackEvents());
    }

    if (repeatMode === RepeatMode.Track) {
      dispatch(seekTo(0));
      dispatch(resume());

      if (queue && currentTrackId) {
        dispatch(
          trackEvent({
            eventName: 'TrackPlayed',
            trackId: currentTrackId!,
            context: {
              source: queue.context.source,
              type: queue.context.type,
              id: queue.context.id,
            },
            additionalData: {
              trigger: 'Looped',
              isShuffle,
              isRepeatQueue: false,
              isRepeatSingle: true,
            },
          }),
        );
      }
    } else if (hasNext) {
      dispatch(playNextTrack(true));
    } else {
      dispatch(seekTo(0));
      dispatch(pause());
    }
  };

export const changeRepeatMode =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentMode = selectRepeatMode(getState());
    const newMode =
      currentMode === RepeatMode.Off
        ? RepeatMode.Queue
        : currentMode === RepeatMode.Queue
        ? RepeatMode.Track
        : RepeatMode.Off;

    dispatch(setRepeatMode(newMode));
  };

export const changeRateMode =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentMode = selectRateMode(getState());
    const newMode = currentMode === 1 ? 1.5 : currentMode === 1.5 ? 2 : 1;

    try {
      dispatch(setRateMode(newMode));
      await TrackPlayer.setRateMode(newMode);
    } catch (e) {
      dispatch(setRateMode(currentMode));
    }
  };

export const changeVolume = (volume: number) => (dispatch: AppDispatch) => {
  dispatch(setVolume(volume));
  TrackPlayer.setVolume(volume);
};

export const disableShuffle = () => (dispatch: AppDispatch) => {
  dispatch(setShuffleMode({shuffledItems: []}));
};

export const enableShuffle =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const items = selectQueue(getState())?.items || [];

    dispatch(
      setShuffleMode({
        shuffledItems: shuffleQueue(items),
      }),
    );
  };

export const toggleShuffle =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const isShuffle = selectIsShuffle(getState());

    if (isShuffle) {
      dispatch(disableShuffle());
    } else {
      dispatch(enableShuffle());
    }
  };

export const stopPlayer = () => async (dispatch: AppDispatch) => {
  await TrackPlayer.reset();
  dispatch(setQueue(null));
};

export const resetPlayer = () => async (dispatch: AppDispatch) => {
  await TrackPlayer.reset();
  dispatch(setIsPlaying(false));
  dispatch(setQueue(null));
};
