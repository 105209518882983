import React from 'react';
import {ReactNode} from 'react';
import {ScrollView, View} from 'react-native';

import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import OpacityGradient from '@/components/OpacityGradient';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useGenerateFeedMutation} from '@/queries/feed';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {GENRES, Genre, IFeedItem, IRefillFeedItem} from '@/types/feed';

import BaseFeedCard from './BaseFeedCard';
import {styles} from './common.styles';

export interface IGenresFeedCardProps {
  item: IRefillFeedItem;
  onEnd?: () => void;
  genres?: Genre[];
  feature: ReactNode;
  toggleHide?: (feedItem: IFeedItem) => void;
}

// bigger than 5 to avoid the "few tracks left" toast
// smaller than 15 (current page size)
// smallish to avoid hijacking the feed
const NUM_REFILL_ITEMS = 7;

export function useGenerateGenreFeed(
  onEnd?: () => void,
  insertAtPosition?: number,
  limit?: number,
) {
  const toast = useToast();
  const activeUserId = useAppSelector(selectActiveUserId);

  const {generateFeed, mutation} = useGenerateFeedMutation(
    activeUserId,
    undefined,
    insertAtPosition,
    limit,
  );

  const generateGenre = (genre: string) => () =>
    generateFeed([{name: 'genre', args: [genre]}], {
      onSuccess: (generatedFeedItemsCount, _variables, _context) => {
        if (generatedFeedItemsCount === 0) {
          toast.showToast({
            textId: 'feed.genreRefill.noMoreTracks',
            values: {genre},
          });
        } else {
          if (generatedFeedItemsCount <= 5) {
            toast.showToast({
              textId: 'feed.genreRefill.fewTracksLeft',
              values: {genre, count: generatedFeedItemsCount},
            });
          }
          // sleep for a bit to allow the new feed items to be rendered
          setTimeout(() => {
            onEnd?.();
          }, 500);
        }
      },
      onError: (_error, _variables, _options) => {
        toast.showToast({
          textId: 'feed.genreRefill.error',
        });
      },
    });

  return {generateGenre, mutation};
}

const GenresFeedCard: React.FC<IGenresFeedCardProps> = ({
  item,
  onEnd,
  feature,
  genres = GENRES,
  toggleHide,
}) => {
  const style = useThemedStyles(styles);

  const {mutation, generateGenre} = useGenerateGenreFeed(
    onEnd,
    item?.position,
    NUM_REFILL_ITEMS,
  );

  return (
    <BaseFeedCard>
      {toggleHide && (
        <IconButton
          onPress={() => toggleHide(item)}
          icon={{
            provider: 'custom',
            name: 'visibilityOff',
          }}
          style={style.dismissButton}
        />
      )}

      <ScrollView
        contentContainerStyle={style.genreContainer}
        showsVerticalScrollIndicator={false}>
        {feature}
        {genres.map(g => (
          <Button
            key={g.key}
            isLoading={
              mutation.isPending &&
              mutation.variables?.flatMap(it => it.args).includes(g.key)
            }
            text={g.text}
            textProps={{weight: 'regular', size: 's'}}
            loaderTextProps={{weight: 'regular', size: 's'}}
            onPress={generateGenre(g.key)}
            style={style.refillButton}
          />
        ))}
      </ScrollView>
      <View style={style.gradientContainer}>
        <OpacityGradient
          color={'background'}
          style={style.gradient}
          vertical={true}
          y2={95}
        />
      </View>
    </BaseFeedCard>
  );
};

export default GenresFeedCard;
