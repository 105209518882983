import {useNavigation} from '@react-navigation/native';
import React, {useCallback, useRef, useState} from 'react';
import {useWindowDimensions} from 'react-native';
import {interpolate} from 'react-native-reanimated';
import Carousel, {
  type ICarouselInstance,
} from 'react-native-reanimated-carousel';
import {throttle} from 'throttle-debounce';

import CarouselContainer from '../CarouselContainer/CarouselContainer';
import {useMainPlayerWithFeed} from '../FeedCard/useFeedAudio';
import FeedHotkeys from '../FeedHotkeys/FeedHotkeys';
import PreventPressOnSwipe from '@/components/PreventPressOnSwipe';
import {useAppSelector} from '@/hooks/useRedux';
import {useResponsive} from '@/hooks/useResponsive';
import {selectAreHotkeysActive} from '@/store/feed';
import {useThemedStyles} from '@/theme';
import {IFeedItem, ILocalMessageFeedItem} from '@/types/feed';
import {RootStackNavigationParams, Routes} from '@/types/routes';

import {styles, CAROUSEL_HEIGHT} from './FeedCarousel.style';
import {IFeedCarouselProps, renderFeedItem} from './shared';

const MINIMUM_HOTKEYS_WINDOW_HEIGHT = 800;

const FeedCarousel: React.FC<IFeedCarouselProps> = ({
  feedItems,
  activeIndex,
  setActiveIndex,
  toggleLike,
  toggleHide,
  onEndReached,
  setScrollToTop,
}) => {
  useMainPlayerWithFeed();

  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  const {currentItem, handleProgressChange} = useCurrentCarouselItem(feedItems);
  const ref = useRef<ICarouselInstance | null>(null);

  const next = useRef(() => ref.current?.next()).current;

  const [enabled, setEnabled] = useState(true);
  const renderItem = useCallback(
    ({
      item,
      index,
    }: {
      item: IFeedItem | ILocalMessageFeedItem;
      index: number;
    }) => {
      const isActive = index === activeIndex;
      const realToggleHide = toggleHide ? () => {
        if (index === feedItems.length - 1) {
          ref.current?.scrollTo({index: index - 1, animated: true});
        }
        toggleHide(item);
      } : toggleHide
      return renderFeedItem({
        item,
        isActive,
        toggleHide: realToggleHide,
        toggleLike,
        next,
        setCarouselEnabled: setEnabled,
      });
    },
    [toggleHide, toggleLike, activeIndex],
  );

  const animationStyle = useCallback((value: number) => {
    'worklet';

    const rotateZ = `${interpolate(value, [-1, 0, 1], [-5, 0, 5])}deg`;
    const translateX = interpolate(value, [-1, 0, 1], [-340, 0, 340]);
    const translateY = interpolate(value, [-1, 0, 1], [-13, 0, -13]);
    // We might want to scale this smaller if we are pan-gesturing on the pagination
    // by sharing a value with ThumbnailPagination (maybe passing onPanBegin
    // / onPanEnd or a shared value).

    return {
      transform: [{rotateZ}, {translateX}, {translateY}],
      opacity: interpolate(value, [-1, 0, 1], [0.2, 1, 0.2]),
    };
  }, []);

  const currentTrack =
    currentItem && 'track' in currentItem ? currentItem.track : null;

  const {isMobile} = useResponsive();

  const {height} = useWindowDimensions();
  const shouldShowHotkeys = !isMobile && height > MINIMUM_HOTKEYS_WINDOW_HEIGHT;

  const areHotkeysActive = useAppSelector(selectAreHotkeysActive);

  const onCollect = currentTrack
    ? () => {
        navigation.navigate(Routes.Collect, {slug: currentTrack.slug});
      }
    : undefined;

  return (
    <CarouselContainer carouselRef={ref} feedLength={feedItems.length}>
      <PreventPressOnSwipe>
        <Carousel
          width={300}
          height={CAROUSEL_HEIGHT}
          style={style.carousel}
          ref={_ref => {
            ref.current = _ref;
            setScrollToTop?.(() => _ref?.scrollTo({index: 0, animated: true}));
          }}
          customAnimation={animationStyle}
          loop={false}
          autoFillData={false}
          autoPlay={false}
          data={feedItems}
          renderItem={renderItem}
          windowSize={4}
          onProgressChange={handleProgressChange}
          onScrollEnd={index => {
            setActiveIndex(index);
            // if there's less than 3 items left, we should fetch more
            // if there's less than 3 items in total, we don't need pagination
            // it's possible to skip an index if we're swiping/scrolling fast
            if (index >= feedItems.length - 3) {
              onEndReached?.();
            }
          }}
          defaultIndex={
            activeIndex < feedItems.length ? activeIndex : feedItems.length - 1
          }
          enabled={enabled}
        />
        {shouldShowHotkeys && (
          <FeedHotkeys
            disabled={!areHotkeysActive}
            activeFeedItem={currentItem ?? feedItems[feedItems.length - 1]}
            toggleHide={toggleHide}
            toggleLike={toggleLike}
            onMoveLeft={ref.current?.prev}
            onMoveRight={ref.current?.next}
            onCollect={onCollect}
          />
        )}
      </PreventPressOnSwipe>
    </CarouselContainer>
  );
};

/**
 * More responsive than using onSnapToItem, which has a slight delay.
 */
const useCurrentCarouselItem = (
  feedItems: (IFeedItem | ILocalMessageFeedItem)[],
) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentItem = feedItems[currentIndex];

  const handleProgressChange = useCallback(
    throttle(100, (_offsetProgress, absoluteProgress) => {
      setCurrentIndex(Math.round(absoluteProgress));
    }),
    [],
  );

  return {
    currentIndex,
    currentItem,
    handleProgressChange,
  };
};

export default React.memo(FeedCarousel);
