import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header';
import IconButton from '@/components/IconButton';
import InfinityList from '@/components/InfinityList';
import LineSeparator from '@/components/LineSeparator';
import Screen from '@/components/Screen';
import {useActiveUser} from '@/hooks/useActiveUser';
import NotificationsEnableBanner from '@/modules/Notifications/components/NotificationsEnableBanner';
import {useNotificationsPermissionQuery} from '@/modules/Notifications/pushNotifications';
import {useNotificationHistoryQuery} from '@/modules/Notifications/query';
import NotificationItem, {
  ESTIMATED_ITEM_SIZE,
} from '@/modules/Notifications/screens/Notifications/NotificationItem';
import NotificationsEmptyState from '@/modules/Notifications/screens/Notifications/NotificationsEmptyState';
import {useSyncReadStatus} from '@/modules/Notifications/screens/Notifications/useSyncReadStatus';
import {useThemedStyles} from '@/theme';
import {RootStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './Notifications.style';

const Notifications: FC = () => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  const {areNotificationsEnabled} = useNotificationsPermissionQuery();

  const activeUser = useActiveUser();
  const {notifications, query: notificationsQuery} =
    useNotificationHistoryQuery(activeUser?.id);

  useSyncReadStatus(notifications);

  return (
    <Screen>
      <Header
        showBack
        titleId="notifications.title"
        rightActions={
          <IconButton
            icon={{provider: 'custom', name: 'settings'}}
            onPress={() =>
              navigation.navigate(Routes.MainNavigation, {
                screen: Routes.NotificationSettings,
              })
            }
          />
        }
      />
      <AsyncContent
        isLoading={notificationsQuery.isPending}
        data={notifications}
        renderContent={fetchedNotifications => {
          return (
            <InfinityList
              contentContainerStyle={style.listContent}
              ListHeaderComponent={
                areNotificationsEnabled ? null : (
                  <View style={style.listHeader}>
                    <NotificationsEnableBanner />
                  </View>
                )
              }
              data={fetchedNotifications}
              renderItem={({item: notification}) => (
                <NotificationItem notification={notification} />
              )}
              keyExtractor={notification => notification.id}
              itemSize={ESTIMATED_ITEM_SIZE}
              ListEmptyComponent={
                areNotificationsEnabled ? <NotificationsEmptyState /> : null
              }
              ItemSeparatorComponent={LineSeparator}
              refresh={notificationsQuery.refetch}
            />
          );
        }}
      />
    </Screen>
  );
};

export default Notifications;
