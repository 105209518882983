import {useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useBalance, useBlockNumber} from 'wagmi';

// https://wagmi.sh/react/guides/migrate-from-v1-to-v2#removed-watch-property
export const useBalanceWatcher = () => {
  const queryClient = useQueryClient();
  const {data: blockNumber} = useBlockNumber({watch: true});
  const {queryKey} = useBalance({query: {enabled: false}});

  useEffect(() => {
    queryClient.invalidateQueries({queryKey});
  }, [blockNumber, queryClient]);
};
