import {useNavigation} from '@react-navigation/native';
import React, {Fragment, useState} from 'react';
import {ScrollView, View} from 'react-native';

import AsyncContent from '@/components/AsyncContent';
import CardButton from '@/components/CardButton';
import Header from '@/components/Header';
import Icon from '@/components/Icon';
import LineSeparator from '@/components/LineSeparator';
import Loader from '@/components/Loader';
import Screen from '@/components/Screen';
import SearchInput from '@/components/SearchInput/SearchInput';
import Space from '@/components/Space';
import StaticImage from '@/components/StaticImage';
import Switch from '@/components/Switch';
import Text from '@/components/Text';
import Touchable from '@/components/Touchable/Touchable';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useDebouncedSearch} from '@/hooks/useDebouncedSearch';
import NotificationsEnableBanner from '@/modules/Notifications/components/NotificationsEnableBanner';
import {
  enableNotifications,
  useNotificationsPermissionQuery,
} from '@/modules/Notifications/pushNotifications';
import {useUpdateUserMetadataMutation} from '@/queries/user';
import {useFollowedArtistPlaylists} from '@/screens/Library/hooks/useFollowedPlaylists';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {MainStackNavigationParams, Routes} from '@/types/routes';

import {ARTIST_CARD_HEIGHT, styles} from './NotificationSettings.style';

const NotificationSettings = () => {
  const style = useThemedStyles(styles);
  const activeUser = useActiveUser();
  const navigation = useNavigation<MainStackNavigationParams>();

  const {areNotificationsEnabled} = useNotificationsPermissionQuery();
  const updateUserMetadataMutation = useUpdateUserMetadataMutation();

  const [search, setSearch] = useState('');
  const {clearSearch} = useDebouncedSearch({
    search,
    setSearch,
  });

  const {followedArtistPlaylists, query: followedArtistsQuery} =
    useFollowedArtistPlaylists();
  const artists = followedArtistPlaylists.map(
    playlist => playlist.followedArtist,
  );
  const searchFilteredArtists = artists.filter(artist =>
    artist.name.toLowerCase().includes(search.toLowerCase()),
  );

  const defaultAvatar = (
    <Icon name="userMusic" provider="custom" size={ARTIST_CARD_HEIGHT * 0.7} />
  );

  if (!activeUser) {
    return null;
  }
  const notificationSettings = activeUser.metadata.notificationSettings;

  const onToggleArtistSetting = async (key: string, value: boolean) => {
    updateUserMetadataMutation.mutate({
      user: activeUser,
      metadataUpdate: {
        notificationSettings: {
          artistProfile: {
            [key]: value,
          },
        },
      },
    });
  };

  const onToggleTrackDropEnabledSetting = async (value: boolean) => {
    updateUserMetadataMutation.mutate({
      user: activeUser,
      metadataUpdate: {
        notificationSettings: {
          collectorProfile: {
            trackDrop: {
              enabled: value,
            },
          },
        },
      },
    });
  };

  const onToggleTrackDropSetting = async (artistId: string, value: boolean) => {
    updateUserMetadataMutation.mutate({
      user: activeUser,
      metadataUpdate: {
        notificationSettings: {
          collectorProfile: {
            trackDrop: {
              artists: {
                [artistId]: value,
              },
            },
          },
        },
      },
    });
  };

  return (
    <Screen>
      <Header showBack titleId="notificationSettings.title" />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={style.scrollContainer}
        contentContainerStyle={style.scrollContent}>
        {!areNotificationsEnabled && <NotificationsEnableBanner />}

        {areNotificationsEnabled && (
          <Space>
            {activeUser?.artistProfile && (
              <View style={style.optionGroup}>
                <Text
                  id="notificationSettings.artistProfile"
                  size="m"
                  weight="semibold"
                />
                <Space h="xs" />
                <View style={style.optionContainer}>
                  <Text
                    id="notificationSettings.artistProfile.collects"
                    size="xs"
                    weight="light"
                  />
                  <Switch
                    value={notificationSettings.artistProfile.collects}
                    setValue={(value: boolean) => {
                      onToggleArtistSetting('collects', value);
                    }}
                  />
                </View>
                <Space h="xxs" />
                <LineSeparator />
                <Space h="xxs" />
                <View style={style.optionContainer}>
                  <Text
                    id="notificationSettings.artistProfile.tips"
                    size="xs"
                    weight="light"
                  />
                  <Switch
                    value={notificationSettings.artistProfile.tips}
                    setValue={(value: boolean) => {
                      onToggleArtistSetting('tips', value);
                    }}
                  />
                </View>
              </View>
            )}
            <Space h="m" />
            <Text
              id="notificationSettings.collectorProfile"
              size="m"
              weight="semibold"
            />
            <Space h="xs" />

            <View style={style.optionGroup}>
              <View style={style.optionContainer}>
                <Text
                  id="notificationSettings.collectorProfile.dropTrack"
                  size="xs"
                  weight="light"
                />
                <Switch
                  value={
                    notificationSettings.collectorProfile.trackDrop.enabled
                  }
                  setValue={(value: boolean) => {
                    onToggleTrackDropEnabledSetting(value);
                  }}
                />
              </View>
              <Space h="l" />
              {notificationSettings.collectorProfile.trackDrop.enabled && (
                <>
                  <View style={style.searchBox}>
                    <SearchInput
                      value={search}
                      setValue={setSearch}
                      onClear={clearSearch}
                      autoFocus={false}
                      placeholderId="notificationSettings.collectorProfile.dropTrack.search"
                    />
                  </View>
                  <Space h="xs" />

                  <AsyncContent
                    isLoading={followedArtistsQuery.isLoading}
                    loaderComponent={<Loader />}>
                    {() =>
                      searchFilteredArtists.length > 0 ? (
                        searchFilteredArtists.map(artist => (
                          <Fragment key={artist.id}>
                            <Touchable
                              style={style.optionContainer}
                              onPress={() => {
                                navigation.push(Routes.Artist, {
                                  slugOrId: artist.slug,
                                });
                              }}>
                              <View style={style.artistInfo}>
                                <View style={style.imageContainer}>
                                  {artist.avatarUrl ? (
                                    <StaticImage
                                      style={style.imageWrapper}
                                      source={{uri: artist.avatarUrl}}
                                      resizeWidth={ImageSize.thumbnail}
                                      fallback={defaultAvatar}
                                    />
                                  ) : (
                                    <View style={style.imageWrapper}>
                                      {defaultAvatar}
                                    </View>
                                  )}
                                </View>
                                <Text weight="semibold" numberOfLines={1}>
                                  {artist.name}
                                </Text>
                              </View>

                              <Switch
                                value={
                                  notificationSettings.collectorProfile
                                    .trackDrop.artists[artist.id] !== false
                                }
                                setValue={(value: boolean) => {
                                  onToggleTrackDropSetting(artist.id, value);
                                }}
                              />
                            </Touchable>
                            <LineSeparator />
                          </Fragment>
                        ))
                      ) : (
                        <Space h="l">
                          <Text
                            id="notificationSettings.noFollowedArtists"
                            size="s"
                            weight="regular"
                            align="center"
                          />
                        </Space>
                      )
                    }
                  </AsyncContent>
                </>
              )}
            </View>
          </Space>
        )}
      </ScrollView>
    </Screen>
  );
};

export default NotificationSettings;
