import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {fetchTrendingTracks} from '@/api/trending';
import {useDbQuery} from '@/queries/db';
import {QueryKeys} from '@/types/queryKeys';
import {getTracksList} from '@/utils/db';

export const useTrendingTracksQuery = () => {
  const {db, updateDb} = useDbQuery();
  const query = useQuery({
    queryKey: [QueryKeys.trendingTracks],
    queryFn: async () => {
      const tracks = await fetchTrendingTracks();

      updateDb({tracks});

      return tracks.map(({id}) => id);
    },
  });

  const trendingTracks = useMemo(
    () => getTracksList(db, query.data),
    [db.tracks, query.data],
  );

  return {
    trendingTracks,
    query,
  };
};
