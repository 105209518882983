import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon';
import SocialShareButtons from '@/components/SocialShareButtons';
import Text from '@/components/Text';
import {TrackCardPreview} from '@/components/TrackCard';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {awaitScreenTransition} from '@/utils/navigation';
import {
  getTrackUrl,
  shareOnLens,
  shareOnTwitter,
  shareOnWarpcast,
} from '@/utils/share';

import {styles} from './UploadSuccess.style';

interface IProps {
  uploadedTrack: ITrack;
  showNewArtistSection: boolean;
  onClose: () => void;
}

const UploadSuccess: FC<IProps> = ({
  uploadedTrack,
  showNewArtistSection,
  onClose,
}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  return (
    <View style={style.successContainer}>
      <Icon name="vinylSuccess" provider="custom" size={72} />
      <View style={style.successTexts}>
        <Text
          weight="semibold"
          size="l"
          align="center"
          id="dropOnSpinamp.success.title"
        />
        <Text align="center" id="dropOnSpinamp.success.description" />
      </View>
      <View style={style.trackCard}>
        <TrackCardPreview
          track={uploadedTrack}
          onPress={async () => {
            onClose();
            await awaitScreenTransition();
            navigation.navigate(Routes.MainNavigation, {
              screen: Routes.Track,
              params: {slug: uploadedTrack.slug},
            });
          }}
          suffixText={{
            id: 'dropOnSpinamp.success.view',
            weight: 'semibold',
          }}
        />
      </View>
      <SocialShareButtons
        shareOnTwitter={() =>
          shareOnTwitter(
            `Just dropped my new track "${uploadedTrack.title}" on @spin_amp ~ Listen and collect it now:`,
            getTrackUrl(uploadedTrack),
          )
        }
        shareOnWarpcast={() =>
          shareOnWarpcast(
            `Just dropped my new track "${uploadedTrack.title}" on @spinamp ~ Listen and collect it now:`,
            getTrackUrl(uploadedTrack),
          )
        }
        shareOnLens={() =>
          shareOnLens(
            `Just dropped my new track "${uploadedTrack.title}" on @lens/spinamp ~ Listen and collect it now:`,
            getTrackUrl(uploadedTrack),
          )
        }
      />
      {showNewArtistSection && (
        <View style={style.artistInfo}>
          <View style={style.artistInfoTexts}>
            <Text
              size="l"
              weight="semibold"
              align="center"
              id="dropOnSpinamp.success.artist.title"
            />
            <Text align="center" id="dropOnSpinamp.success.artist.message" />
          </View>
          <CardButton
            onPress={() => {
              onClose();
              navigation.navigate(Routes.MainNavigation, {
                screen: Routes.Artist,
                params: {slugOrId: uploadedTrack.artist.slug},
              });
            }}
            icon={{name: 'edit', provider: 'custom'}}
            text={{id: 'dropOnSpinamp.success.artist.edit'}}
          />
        </View>
      )}
    </View>
  );
};

export default UploadSuccess;
