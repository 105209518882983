import React, {FC, useRef, useState} from 'react';
import {View} from 'react-native';

import {useOptimizedImageSource} from '@/hooks/useOptimizedImageSource';
import {useThemedStyles} from '@/theme';

import {styles} from './StaticImage.style';
import {IStaticImageProps} from './types';

const StaticImage: FC<IStaticImageProps> = ({
  style,
  source,
  resizeWidth,
  onLoad: _onLoad,
  resizeMode = 'cover',
  fallback,
  hasBorderRadius,
  ...rest
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const optimizedSource = useOptimizedImageSource(source, resizeWidth);
  const imageStyle = useThemedStyles(styles);

  const [imageError, setImageError] = useState(false);

  const onError = () => {
    setImageError(true);
  };

  const onLoad = () => {
    setImageError(false);

    if (_onLoad && imageRef.current) {
      _onLoad({
        width: imageRef.current.naturalWidth,
        height: imageRef.current.naturalHeight,
      });
    }
  };

  const borderRadiusStyle = hasBorderRadius ? imageStyle.hasBorderRadius : {};

  return (
    <View style={style} {...rest}>
      <img
        ref={imageRef}
        // @ts-ignore
        src={optimizedSource.uri || optimizedSource}
        loading="lazy"
        width="100%"
        height="100%"
        alt=""
        onError={onError}
        onLoad={onLoad}
        draggable="false"
        style={{
          userSelect: 'none',
          objectFit: resizeMode,
          opacity: imageError ? '0' : '1',
          ...(borderRadiusStyle as any),
        }}
      />
      {fallback && imageError && (
        <View style={[imageStyle.fallbackWrapper]}>{fallback}</View>
      )}
    </View>
  );
};

export default StaticImage;
