import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {
  fetchTrackByIdOrSlug,
  fetchTrackByUrlParam,
  fetchTrackDetails,
  fetchTracksByIds,
  getEarliestTrackReleaseTime,
} from '@/api/track';
import {useDbQuery} from '@/queries/db';
import {QueryKeys} from '@/types/queryKeys';
import {getAllTracksList, getTracksList} from '@/utils/db';

export const useTrackBySlugQuery = (slug: string) => {
  const {db, updateDb} = useDbQuery();

  // we fetch full track based on slug and save corresponding track id in cache, so we always make sure
  // we have proper oldest matching slug for prettified slugs
  const query = useQuery({
    queryKey: [QueryKeys.trackBySlug, slug],
    queryFn: async () => {
      const track = await fetchTrackByUrlParam(slug);
      if (track) {
        updateDb({
          tracks: [track],
        });

        return track.id;
      }
    },
  });

  const trackId = query.data;
  const track = useMemo(() => {
    if (trackId) {
      return db.tracks[trackId];
    }

    return getAllTracksList(db).find(t => t.slug === slug);
  }, [trackId, slug, db.tracks]);

  return {
    track,
    query,
  };
};

export const useTrackDetailsQuery = (trackId?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.trackDetails, trackId],
    queryFn: () => fetchTrackDetails(trackId!),
    enabled: !!trackId,
  });

  return {
    trackDetails: query.data,
    query,
  };
};

export const useTracksByIdsQuery = (trackIds: string[] = []) => {
  const {db, updateDb} = useDbQuery();

  const query = useQuery({
    queryKey: [QueryKeys.tracksByIds, ...trackIds],
    queryFn: async () => {
      const tracks = await fetchTracksByIds(trackIds!);
      updateDb({tracks});
      return true;
    },
    enabled: trackIds.length > 0,
    staleTime: 1000 * 60 * 5,
    gcTime: 0,
  });

  const tracks = useMemo(
    () => getTracksList(db, trackIds),
    [db.tracks, trackIds],
  );

  return {
    tracks,
    query,
  };
};

export const useEmbedTrackQuery = (id?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.trackEmbed, id],
    queryFn: () => fetchTrackByIdOrSlug(id!),
    enabled: !!id,
  });

  return {
    trackDetails: query.data,
    query,
  };
};

export const useEarliestTrackReleaseTimeQuery = (
  trackId?: string,
  userId?: string,
) => {
  const query = useQuery({
    queryKey: [QueryKeys.earliestTrackReleaseTime, trackId, userId],
    queryFn: () => getEarliestTrackReleaseTime(trackId!, userId!),
    enabled: !!trackId && !!userId,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  return {
    releaseTime: query.data ? new Date(query.data) : undefined,
    query,
  };
};
