import {chainsByCode} from '@/constants/chains';
import {GENRES} from '@/types/feed';

export const premintChain = chainsByCode.optimism;
export const ZORA_FEE = '0.000777';
export const currency = premintChain.chain.nativeCurrency;

export const PodcastGenreKey = 'Podcast';

export const genres = GENRES.map(g => ({text: g.text, value: g.key})).filter(
  g => g.value !== PodcastGenreKey,
);

export const artistAvatarSize = {
  min: 400,
  max: 5000,
  preview: 200,
};

export const artworkSize = {
  min: 400,
  max: 5000,
  preview: 200,
};
