import NetInfo from '@react-native-community/netinfo';
import {createAsyncStoragePersister} from '@tanstack/query-async-storage-persister';
import {
  QueryClient,
  onlineManager,
  focusManager,
  Query,
  MutationCache,
} from '@tanstack/react-query';
import {persistQueryClient} from '@tanstack/react-query-persist-client';
import {AppState} from 'react-native';
import {deserialize, serialize} from 'wagmi';

import {Sentry} from '@/services/sentry';
import {storage} from '@/services/storage';
import {CACHE_EXCLUDED_KEYS, QueryKeys} from '@/types/queryKeys';
import {isLocalDev, isWeb} from '@/utils/platform';

const CACHE_VERSION = 22;
const CACHE_STORAGE_KEY = 'REACT_QUERY_OFFLINE_CACHE';
const getStorageKey = (version: number) => `${CACHE_STORAGE_KEY}_v${version}`;

const CACHE_TIME = Infinity;

const mutationCache = new MutationCache({
  onError: (error: any) => {
    Sentry.captureException(error, {
      extra: {
        context: 'Global mutation error handler',
      },
    });
  },
});

export const queryClient = new QueryClient({
  mutationCache,
  defaultOptions: {
    queries: {
      staleTime: 0,
      gcTime: CACHE_TIME,
      refetchOnWindowFocus: !isWeb || !isLocalDev,
    },
  },
});

queryClient.setQueryDefaults([QueryKeys.walletConnector], {
  gcTime: 0,
});

const persister = createAsyncStoragePersister({
  key: getStorageKey(CACHE_VERSION),
  storage,
  throttleTime: 3000,
  serialize,
  deserialize: cachedData => {
    const parsedCached: any = deserialize(cachedData);

    return {
      ...parsedCached,
      clientState: {
        ...parsedCached.clientState,
        queries: parsedCached.clientState.queries.filter(
          // @ts-ignore
          (query: Query) => !CACHE_EXCLUDED_KEYS.includes(query.queryKey[0]),
        ),
      },
    };
  },
});

const clearDeprecatedCache = async () => {
  try {
    // remove legacy cache
    if (CACHE_VERSION > 1) {
      await Promise.all(
        Array.from({length: CACHE_VERSION - 1}).map((_, i) =>
          storage.removeItem(getStorageKey(i + 1)),
        ),
      );
    }
  } catch (error) {
    //ignore error
  }
};

export const setupReactQuery = async (): Promise<void> => {
  if (!isWeb) {
    onlineManager.setEventListener(setOnline => {
      return NetInfo.addEventListener(state => {
        setOnline(!!state.isConnected);
      });
    });

    focusManager.setEventListener(handleFocus => {
      const subscription = AppState.addEventListener('change', state => {
        if (state === 'active') {
          handleFocus();
        }
      });

      return () => {
        subscription.remove();
      };
    });
  }

  clearDeprecatedCache();

  const [, restorePromise] = persistQueryClient({
    queryClient,
    persister,
    maxAge: CACHE_TIME,
  });

  await restorePromise;
};
