import {IDropForm} from '@/modules/DropOnSpinamp/types';
import {NotificationEntityType} from '@/modules/Notifications/types';
import {ITransactionEvent} from '@/modules/Transactions/types';
import {PlayContextSource, PlayContextType} from '@/types/common';
import {IPlaybackEventPayload, TrackPlayedTrigger} from '@/types/events';
import {IFeedEntityType, IFeedUserAction} from '@/types/feed';
import {Routes} from '@/types/routes';
import mixpanel, {setUserProperty} from '@/utils/mixpanel';

export const analytics = {
  // run on login
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  // run on logout
  reset: () => {
    mixpanel.reset();
  },
  setUserProperty: (key: string, value: any) => {
    setUserProperty(key, value);
  },
  applicationLoaded: () => {
    mixpanel.track('App loaded');
  },
  platformLinkPressed: (screen: Routes, id: string) => {
    mixpanel.track('Platform link pressed', {
      screen,
      id,
    });
  },
  themeEditVisited: (
    screen: Routes.CollectorThemeEdit | Routes.ArtistThemeEdit,
    id: string,
  ) => {
    mixpanel.track('Theme edit visited', {
      screen,
      id,
    });
  },
  notificationRequested: (
    type: NotificationEntityType,
    screen: Routes,
    id: string,
  ) => {
    mixpanel.track(`${type}_notification_requested`, {screen, id});
  },
  feedItemReacted: (
    id: string,
    userAction: IFeedUserAction,
    entityType: IFeedEntityType,
  ) => {
    mixpanel.track('feed_item_reacted', {
      id,
      userAction,
      entityType,
    });
  },
  feedGenerationRequested: () => {
    mixpanel.track('feed_generation_requested');
  },
  feedOnboardingCompleted: (type: string) => {
    mixpanel.track('feed_onboarding_completed', {type});
  },
  feedCollectClicked: (id: string) => {
    mixpanel.track('feed_collect_clicked', {id});
  },
  feedCardPlayed: (id: string, feedShouldPlay: boolean) => {
    mixpanel.track('feed_card_played', {id, feedShouldPlay});
  },
  feedTrackEnded: (id: string) => {
    mixpanel.track('feed_track_ended', {id});
  },
  feedAudioPlayed: (id: string) => {
    mixpanel.track('feed_audio_played', {id});
  },
  feedSpyRequested: (requester: string, requested: string) => {
    mixpanel.track('feed_spy_requested', {requester, requested});
  },
  feedIndexChanged: (index: number, feedId: string, audioPlaying: boolean) => {
    mixpanel.track('feed_index_changed', {index, feedId, audioPlaying});
  },
  dropOnSpinampAudioUploaded: (formId: string, values: IDropForm) => {
    mixpanel.track('drop_on_spinamp_audio_uploaded', {formId, values});
  },
  dropOnSpinampImageUploaded: (formId: string, values: IDropForm) => {
    mixpanel.track('drop_on_spinamp_image_uploaded', {formId, values});
  },
  dropOnSpinampFormSubmitted: (formId: string, values: IDropForm) => {
    mixpanel.track('drop_on_spinamp_form_submitted', {formId, values});
  },
  dropOnSpinampFormValidated: (formId: string, values: IDropForm) => {
    mixpanel.track('drop_on_spinamp_form_validated', {formId, values});
  },
  dropOnSpinampPublishClicked: (formId: string, values: IDropForm) => {
    mixpanel.track('drop_on_spinamp_publish_clicked', {formId, values});
  },
  dropOnSpinampRequestSignature: (formId: string, values: IDropForm) => {
    mixpanel.track('drop_on_spinamp_request_signature', {formId, values});
  },
  dropOnSpinampSigned: (formId: string, values: IDropForm) => {
    mixpanel.track('drop_on_spinamp_signed', {formId, values});
  },
  dropOnSpinampRequestPasskey: (formId: string) => {
    mixpanel.track('drop_on_spinamp_request_passkey', {formId});
  },
  dropOnSpinampComplete: (formId: string, trackId: string) => {
    mixpanel.track('drop_on_spinamp_complete', {formId, trackId});
  },
  dropOnSpinampUploadError: (formId: string, values: IDropForm, error: any) => {
    mixpanel.track('drop_on_spinamp_upload_error', {formId, values, error});
  },
  trackPlaybackStarted: (
    trackId: string,
    source: PlayContextSource,
    playContextType: PlayContextType,
    trigger?: TrackPlayedTrigger,
  ) => {
    mixpanel.track('track_playback_started', {
      trackId,
      source,
      playContextType,
      trigger,
    });
  },
  trackPlaybackCompleted: (
    trackId: string,
    source: PlayContextSource,
    playContextType: PlayContextType,
    trigger?: TrackPlayedTrigger,
  ) => {
    mixpanel.track('track_playback_completed', {
      trackId,
      source,
      playContextType,
      trigger,
    });
  },
  trackPlaylisted: (trackId: string, playlistId: string) => {
    mixpanel.track('track_playlisted', {trackId, playlistId});
  },
  trackUnplaylisted: (trackId: string, playlistId: string) => {
    mixpanel.track('track_unplaylisted', {trackId, playlistId});
  },
  trackFaved: (trackId: string) => {
    mixpanel.track('track_faved', {trackId});
  },
  trackUnfaved: (trackId: string) => {
    mixpanel.track('track_faved', {trackId});
  },
  artistFollowed: (artistId: string) => {
    mixpanel.track('artist_followed', {artistId});
  },
  artistUnfollowed: (artistId: string) => {
    mixpanel.track('artist_unfollowed', {artistId});
  },
  playlistFollowed: (playlistId: string) => {
    mixpanel.track('playlist_followed', {playlistId});
  },
  playlistUnfollowed: (playlistId: string) => {
    mixpanel.track('playlist_unfollowed', {playlistId});
  },
  collectionFollowed: (collectionAddress: string) => {
    mixpanel.track('collection_followed', {collectionAddress});
  },
  collectionUnfollowed: (collectionAddress: string) => {
    mixpanel.track('collection_unfollowed', {collectionAddress});
  },
  trackTransaction: (transaction: ITransactionEvent) => {
    mixpanel.track(
      `${transaction.type}_tx_${transaction.eventName}`,
      transaction,
    );
  },
  topUpStarted: (
    address: string,
    amount: string | undefined,
    chainId: number,
  ) => {
    mixpanel.track('top_up_started', {address, amount, chainId});
  },
  externalWalletConnectStarted: () => {
    mixpanel.track('external_wallet_connect_started');
  },
  promotionBannerPressed: () => {
    mixpanel.track('promotion_banner_pressed');
  },
  routeChanged: (routes: {from: string; to: string}) => {
    mixpanel.track('route_changed', routes);
  },
};

export const trackPlaybackEvent = (playbackEvent: IPlaybackEventPayload) => {
  switch (playbackEvent.eventName) {
    case 'TrackPlayed': {
      analytics.trackPlaybackStarted(
        playbackEvent.trackId,
        playbackEvent.context.source,
        playbackEvent.context.type,
        playbackEvent.additionalData?.trigger,
      );
      break;
    }
    case 'TrackEnded': {
      analytics.trackPlaybackCompleted(
        playbackEvent.trackId,
        playbackEvent.context.source,
        playbackEvent.context.type,
        playbackEvent.additionalData?.trigger,
      );
      break;
    }
  }
};
