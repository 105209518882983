import React, {FC, useCallback, useRef, useState} from 'react';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import Screen from '@/components/Screen/Screen';
import SpinLoader from '@/components/SpinLoader';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import ChannelPills from '@/screens/Feed/components/ChannelPills';
import {selectShouldPlay, setAreHotkeysActive} from '@/store/feed';
import {selectActiveUserId} from '@/store/user';
import {noop} from '@/utils/functions';

import Feed from './components/Feed';
import {useIsFeedLoading} from './components/useIsFeedLoading';
import { analytics } from '@/utils/analytics';

const ChannelsScreen = () => {
  const {scrollPosition, onScroll, resetScroll} = useAnimatedHeader();

  const myUserId = useAppSelector(selectActiveUserId);
  const [spiedUserId, setSpiedUserId] = useState<string>();

  const activeUserId = spiedUserId || myUserId;

  const dispatch = useAppDispatch();

  const scrollToTopRef = useRef(noop);

  const onSearchFocus = useCallback(() => {
    dispatch(setAreHotkeysActive(false));
  }, []);

  const onSearchBlur = useCallback(() => {
    dispatch(setAreHotkeysActive(true));
  }, []);

  const channelIndexHistory = useRef(new Map());

  const feedShouldPlay = useAppSelector(selectShouldPlay);

  return (
    <Screen>
      <Header
        titleId="channels.title"
        showMenu
        scrollPosition={scrollPosition}
        mockTitle={true}
        rightActions={<ChannelLoadingIndicator userId={activeUserId} />}>
        <ChannelPills
          spiedUserId={spiedUserId}
          onUserSelect={setSpiedUserId}
          scrollToTop={scrollToTopRef}
          onSearchFocus={onSearchFocus}
          onSearchBlur={onSearchBlur}
        />
      </Header>
      <AsyncContent
        data={activeUserId}
        isLoading={!myUserId}
        renderContent={loadedUserId => (
          <Feed
            key={loadedUserId}
            userId={loadedUserId}
            initialIndex={channelIndexHistory.current.get(activeUserId)}
            onIndexChange={index => {
              // Remove this if we want to remember positions other channels too
              if (spiedUserId) {
                return;
              }

              channelIndexHistory.current.set(activeUserId, index);

              analytics.feedIndexChanged(index, loadedUserId, feedShouldPlay);
            }}
            onScroll={onScroll}
            resetHeaderScroll={resetScroll}
            hideActions={loadedUserId !== myUserId}
            scrollToTop={scrollToTopRef}
            setScrollToTop={_scrollToTop =>
              (scrollToTopRef.current = _scrollToTop)
            }
          />
        )}
      />
    </Screen>
  );
};

export const ChannelLoadingIndicator: FC<{userId?: string}> = ({userId}) => {
  const isLoading = useIsFeedLoading(userId);

  if (isLoading) {
    return <SpinLoader />;
  }

  return null;
};

export default ChannelsScreen;
